import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Styled } from './CarouselServicoPorto.styled'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'shared/http'
import { useHistory } from 'react-router-dom'

const CarouselServicoPorto = ({ itens }) => {
  const history = useHistory()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1224, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const authToken = localStorage.getItem('auth-token') || null
  const handleGetUrl = async (url) => {
    debugger
    if (authToken != null) {
      await axios
        .get(url)
        .then((response) => {
          return window.open(response.uri, '_blank')
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      history.push('/Login')
    }
  }
  return (
    <Styled.Content>
      <Container>
        <Row>
          <Col md={12}>
            <Styled.H2
              className="m-2 mb-4"
              onClick={() => {
                window.open('https://www.portoseguro.com.br/app-porto-download')
              }}
            >
              Clique e adquira <strong>Serviços da Porto</strong> com vantagens.
            </Styled.H2>
            <Carousel
              responsive={responsive}
              infinite={true}
              removeArrowOnDeviceType={['tablet', 'mobile']}
            >
              {itens.map((item, i) => {
                return (
                  <Styled.Card
                    className="m-2"
                    key={i}
                    onClick={() => {
                      item.txtTitle === 'Instituto Porto'
                        ? handleGetUrl('/catalog/v1/vendor/61968')
                        : window.open(
                            'https://www.portoseguro.com.br/app-porto-download'
                          )
                    }}
                  >
                    <Styled.ImageContent className="p-3">
                      <Styled.Image src={item.img} />
                    </Styled.ImageContent>
                    <Styled.TxtContent>
                      <Styled.Txt>{item.txtTitle}</Styled.Txt>
                      <div className="desc">
                        <Styled.Description>{item.txtInfo}</Styled.Description>
                      </div>
                    </Styled.TxtContent>
                    <Styled.ContentInfo>
                      {/* <Styled.Info>
                        <Styled.Icon src={CredtCard} />
                        Pague em 12x sem juros no cartão Porto.
                      </Styled.Info> */}
                    </Styled.ContentInfo>
                  </Styled.Card>
                )
              })}
            </Carousel>
            <Styled.txtInfo className="m-2 mb-5">
              *Para resgates, você pode ligar na Central de Atendimento 24h,
              entrar em contato com seu corretor ou solicitar pelo App da Porto.
            </Styled.txtInfo>
          </Col>
        </Row>
      </Container>
    </Styled.Content>
  )
}

export default CarouselServicoPorto
