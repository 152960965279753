import React, { useContext } from 'react'
import { Styled } from './NavItems.styled'
import { ArrowLeftMenu, Doubt } from 'components/Icons/Icons'
import arrowMobile from 'assets/images/ico/ico-arrow-left-white.png'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

import { TabContext } from 'components/Tab/Tab'
import { useCount } from 'util/context/Count'

const NavItems = (props) => {
  const { handleChangeTab, myFunc } = useContext(TabContext)
  const { count, setCount } = useCount()

  const [state, setState] = React.useState({
    isLoggedIn: localStorage.getItem('auth-token'),
    infoUsuario: [],
    isVisibleParaMim: false,
    isVisibleMeusPontos: false,
    msg: '',
    msgType: '',
    linkVitrine: '',
    loadInfo: false,
  })

  const clickLinkMenu = (valueEvent, faq) => {
    if (faq) {
      setCount(0)
    }
    setState({
      ...state,
      isVisibleParaMim: false,
      isVisibleMeusPontos: false,
    })
    if (window.innerWidth < 1024) {
      props.CloseMenu()
    }

    let category = 'geral'
    let action = `clique:${props.position || 'header'}`
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }
  return (
    <Styled.Wrap>
      <Styled.primeiroNivel>
        <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.linkMenu
            to={'/sobre-o-portoplus'}
            onClick={() => clickLinkMenu('Sobre o PortoPlus')}
          >
            <p>Sobre o PortoPlus</p>
          </Styled.linkMenu>
        </Styled.itemPrimeiroNivel>

        <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.linkMenu
            ativo={state.isVisibleParaMim}
            onClick={() => {
              setState({ ...state, isVisibleParaMim: !state.isVisibleParaMim })
            }}
          >
            <p>FAQ</p>
            <Styled.iconeSeta
              className="iconeSeta"
              ativo={state.isVisibleParaMim}
            >
              <ArrowLeftMenu />
              <img src={arrowMobile} alt="" />
            </Styled.iconeSeta>
          </Styled.linkMenu>
          <Styled.Submenu className="submenu" ativo={state.isVisibleParaMim}>
            <ul>
              {/* <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/faq/cartao'}
                  onClick={() => clickLinkMenu('faq-cartao',true)}
                >
                  <Styled.iconeMenu>
                    <Doubt />
                  </Styled.iconeMenu>
                  <span>FAQ Cartão</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>

              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/faq/corretor'}
                  onClick={() => clickLinkMenu('faq-corretor', true)}
                >
                  <Styled.iconeMenu>
                    <Doubt />
                  </Styled.iconeMenu>
                  <span>FAQ Corretor</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel> */}

              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/faq/descontos'}
                  onClick={() => clickLinkMenu('faq-descontos', true)}
                >
                  <Styled.iconeMenu>
                    <Doubt />
                  </Styled.iconeMenu>
                  <span>FAQ Compras Com Desconto</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>
            </ul>
          </Styled.Submenu>
        </Styled.itemPrimeiroNivel>

        <Styled.subMenuMobile>
          <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
            <Styled.ButtonLink
              className={`${count == 1 ? 'active' : ''}`}
              onClick={() => {
                handleChangeTab(1), clickLinkMenu('trocar-pontos')
              }}
            >
              <strong>Trocar Pontos</strong>
            </Styled.ButtonLink>
          </Styled.itemPrimeiroNivel>

          <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
            <Styled.ButtonLink
              className={`${count == 2 ? 'active' : ''}`}
              onClick={() => {
                handleChangeTab(2), clickLinkMenu('ganhar-pontos')
              }}
            >
              Comprar com <strong> desconto</strong>
            </Styled.ButtonLink>
          </Styled.itemPrimeiroNivel>

          <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
            <Styled.ButtonLink
              className={`${count == 3 ? 'active' : ''}`}
              onClick={() => {
                handleChangeTab(3), clickLinkMenu('Comprar-desconto')
              }}
            >
              Comprar e <strong> ganhar pontos</strong>
            </Styled.ButtonLink>
          </Styled.itemPrimeiroNivel>
        </Styled.subMenuMobile>
      </Styled.primeiroNivel>
    </Styled.Wrap>
  )
}

export default NavItems
